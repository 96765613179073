<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <template v-if="error">
      <v-banner
        single-line
        elevation="10"
      >
        <v-icon
          slot="icon"
          color="warning"
          size="36"
        >
          mdi-wifi-strength-alert-outline
        </v-icon>
        <div>
          There was a problem communicating to the server!
          <div class="code">
            <strong>
              {{ error }}
            </strong>
          </div>
        </div>
      </v-banner>
    </template>
    <template>
      <v-card>
        <v-toolbar
          color="success"
          dark
        >
          <v-toolbar-title>Create New Vendor</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-text-field
                  v-model="name"
                  label="Name"
                  :rules="[v => !!v || 'Name is required']"
                  required
                />

                <v-select
                  v-model="vendor_type"
                  :items="items.vendor_types"
                  :rules="[v => !!v || 'Vendor type is required']"
                  label="Vendor Type"
                  required
                />

                <v-combobox
                  v-model="adf_providers"
                  :items="items.lead_providers"
                  label="ADF Providers"
                  multiple
                />

                <v-combobox
                  v-model="adf_services"
                  :items="items.lead_services"
                  label="ADF Services"
                  multiple
                />

                <v-combobox
                  v-model="website_sources"
                  :items="items.sources"
                  label="Website Sources"
                  multiple
                />

                <v-combobox
                  v-model="website_mediums"
                  :items="items.mediums"
                  label="Website Mediums"
                  multiple
                />

                <v-combobox
                  v-model="call_tracking_number"
                  :items="items.call_tracking_numbers"
                  label="Call Tracking Numbers"
                  item-text="phone"
                  item-value="id"
                  multiple
                />

                <v-btn
                  :disabled="!valid"
                  color="success"
                  class="mr-4"
                  @click="save"
                >
                  Save
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </template>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import VendorService from '@/services/VendorService.js'
  import { mapGetters } from 'vuex'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { navigationMixin } from '@/mixins/navigationMixin.js'

  export default {
    name: 'NewVendor',
    mixins: [formatterMixin, navigationMixin],
    data () {
      return {
        items: [],
        search: '',
        loading: true,
        error: '',
        valid: true,
        name: '',
        vendor_type: '',
        adf_providers: [],
        adf_services: [],
        website_sources: [],
        website_mediums: [],
        website_providers: [],
        call_tracking_number: [],
      }
    },
    computed: {
      ...mapGetters(['accountId', 'accountName']),
    },
    watch: {
      accountId () {
        this.loadData()
      },
    },
    created () {
      this.loadData()
    },
    methods: {
      loadData: function () {
        this.items = []
        this.loading = true
        VendorService.getNewVendorData()
          .then(response => {
            // console.log(response)
            this.items = response.data.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              this.error = errorMessage
            }
          })
      },
      validate () {
        self.valid = this.$refs.form.validate()
      },
    },
  }
</script>
